.conversational {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4rem 6rem;
  color: whitesmoke;
}

.videoTutorial {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10rem;
  color: whitesmoke;
}

.videoTutorialContent {
  margin-top: 10%;
}

.videoTutorialBanner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 50px; */
  margin-left: 40px;
  margin-right: 30px;
}

.contentInnerTable {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.eachInnerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.eachInnerContent img {
  margin-top: 30px;
}

.conversational_banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  margin-left: 40px;
  margin-right: 30px;
}

.conversational_personalizedcustomer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 150px;
}

.conversational_personify {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 150px;
}

@media screen and (max-width: 800px) {

  .conversational {
    /* padding: 0rem 2rem; */
    align-items: center;
  }

  .conversational_banner {
    flex-direction: column;
    margin-top: 0px;
  }

  .conversational_personalizedcustomer {
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .conversational_personify {
    flex-direction: column;
    margin-top: 50px;
  }
}

.horizontal_line {
  position: relative;
  width: 350px;
  height: 0;
  border-top: 2px solid #22AADD;
  border-radius: 100%;
  margin: 100px auto 0 auto;
  display: flex;
}

.texttovideo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4rem 6rem;
  color: whitesmoke;
}

.texttovideo_banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  margin-left: 40px;
  margin-right: 30px;
}

@media screen and (max-width: 800px) {

  .horizontal_line {
    width: 300px;
    margin: 0;
  }

  .texttovideo {
    padding: 0rem 2rem;
    align-items: center;
  }

  .videoTutorial {
    padding: 2rem;
  }

  .contentInnerTable {
    flex-direction: column;
  }

  .texttovideo_banner {
    margin-top: 50px;
    flex-direction: column;
  }

  .texttovideo_personalized {
    margin-top: 0px;
  }
}

.texttovideo_personalized {
  display: flex;
  flex-direction: column;
  margin-top: 150px;
}

.personalization {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4rem 6rem;
  color: whitesmoke;
  background: #0C0818;
}

.personalization_banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  margin-left: 40px;
  margin-right: 30px;
}

@media screen and (max-width: 800px) {

  .personalization {
    /* padding: 0rem 2rem; */
    align-items: center;
  }

  .personalization_banner {
    flex-direction: column;
    margin-top: 0px;
  }
}

.useslides {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 4rem 6rem;
  color: whitesmoke;
  margin-top: 150px;
}

.useslides_firstcolumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.useslides_firstcolumn_row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 15px;
}

.useslides_firstcolumn_row p {
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  color: lightgray;
  cursor: pointer;
}

.useslides_firstcolumn_row p:active {
  color: whitesmoke;
}

.useslides_content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 50px;
}

.wrap {
  margin: 100px;
}

.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7%;
}

.carousel {
  position: relative;
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-bottom: 68px;
}

.carousel__item {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  padding: 0 10px;
  opacity: 0;
  filter: drop-shadow(0 2px 2px #555);
  will-change: transform, opacity;
  -webkit-animation: carousel-animate-vertical 30s linear infinite;
  animation: carousel-animate-vertical 30s linear infinite;
}

.carousel__item:nth-child(1) {
  -webkit-animation-delay: calc(3s * -1);
  animation-delay: calc(3s * -1);
}

.carousel__item:nth-child(2) {
  -webkit-animation-delay: calc(3s * 0);
  animation-delay: calc(3s * 0);
}

.carousel__item:nth-child(3) {
  -webkit-animation-delay: calc(3s * 1);
  animation-delay: calc(3s * 1);
}

.carousel__item:nth-child(4) {
  -webkit-animation-delay: calc(3s * 2);
  animation-delay: calc(3s * 2);
}

.carousel__item:nth-child(5) {
  -webkit-animation-delay: calc(3s * 3);
  animation-delay: calc(3s * 3);
}

.carousel__item:nth-child(6) {
  -webkit-animation-delay: calc(3s * 4);
  animation-delay: calc(3s * 4);
}

.carousel__item:nth-child(7) {
  -webkit-animation-delay: calc(3s * 5);
  animation-delay: calc(3s * 5);
}

.carousel__item:nth-child(8) {
  -webkit-animation-delay: calc(3s * 6);
  animation-delay: calc(3s * 6);
}

.carousel__item:nth-child(9) {
  -webkit-animation-delay: calc(3s * 7);
  animation-delay: calc(3s * 7);
}

.carousel__item:last-child {
  -webkit-animation-delay: calc(-3s * 2);
  animation-delay: calc(-3s * 2);
}

@-webkit-keyframes carousel-animate-vertical {
  0% {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }

  3%,
  11.1111111111% {
    transform: translateY(100%) scale(0.7);
    opacity: 0.2;
    visibility: visible;
  }

  14.1111111111%,
  22.2222222222% {
    transform: translateY(0) scale(1);
    opacity: 1;
    visibility: visible;
  }

  25.2222222222%,
  33.3333333333% {
    transform: translateY(-100%) scale(0.7);
    opacity: 0.2;
    visibility: visible;
  }

  36.3333333333% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: visible;
  }

  100% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes carousel-animate-vertical {
  0% {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }

  3%,
  11.1111111111% {
    transform: translateY(100%) translateX(-15%) scale(0.7);
    opacity: 0.2;
    visibility: visible;
  }

  14.1111111111%,
  22.2222222222% {
    transform: translateY(0) scale(1);
    opacity: 1;
    visibility: visible;
  }

  25.2222222222%,
  33.3333333333% {
    transform: translateY(-100%) translateX(-15%) scale(0.7);
    opacity: 0.2;
    visibility: visible;
  }

  36.3333333333% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: visible;
  }

  100% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
}