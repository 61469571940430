.information {
  color: whitesmoke;
  margin-top: 100px;
  margin: 4rem 6rem;
  font-family: "Plus Jakarta Display", Helvetica, Arial, sans-serif !important;
}

.information_heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 50px;
}

.horizontal-line {
  position: relative;
  width: 350px;
  height: 0;
  border-top: 2px solid #22AADD;
  margin: 30px 0;
  display: flex;
}

.horizontal-line::before,
.horizontal-line::after {
  content: '';
  position: absolute;
  top: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-radius: 100%;
}

.horizontal-line::before {
  left: -8px;
  border-width: 8px;
  border-color: #22AADD;
}

.horizontal-line::after {
  right: -8px;
  border-width: 8px;
  border-color:  #22AADD;
}

.first_hover:hover {
  
}
.second_hover:hover {

}
.third_hover:hover {

}

.custom-btn {
  width: 150px;
  height: 50px;
  color: #fff;
  border-radius: 10px;
  padding: 10px 25px;
  /* font-family: 'Lato', sans-serif; */
  font-weight: 400;
  /* background: transparent; */
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  /* box-shadow: 1px 1px 24px 1px rgba(5,5,5,0.74); */
  outline: none;
}

/* 11 */
.btn-11 {
  border: none;
  background: black;
  color: #fff;
  overflow: hidden;
  text-transform: uppercase;
}
.btn-11:hover {
    text-decoration: none;
    color: #fff;
}
.btn-11:before {
    position: absolute;
    content: '';
    display: inline-block;
    top: -180px;
    left: 0;
    width: 30px;
    height: 100%;
    background-color: #fff;
    animation: shiny-btn1 3s ease-in-out infinite;
}
.btn-11:hover{
  opacity: .7;
}
.btn-11:active{
  box-shadow:  4px 4px 6px 0 rgba(255,255,255,.3),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, .2);
}

@keyframes shiny-btn1 {
    0% { -webkit-transform: scale(0) rotate(45deg); opacity: 0; }
    80% { -webkit-transform: scale(0) rotate(45deg); opacity: 0.5; }
    81% { -webkit-transform: scale(4) rotate(45deg); opacity: 1; }
    100% { -webkit-transform: scale(50) rotate(45deg); opacity: 0; }
}

@media screen and (max-width: 800px) {
  .information {
    margin: 0;
  }

  .horizontal-line {
    width: 200px;
    margin: 30px auto;
  }
}