.pagination {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  justify-content: flex-end;
}

.button_arrow {
  border-radius: 100%;
  padding: 8px 10px;
  border: 1px solid gray;
  color: gray;
  background: transparent;
  margin: 5px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button_number {
  border-radius: 100%;
  padding: 8px 13px;
  border: 1px solid gray;
  color: gray;
  background: transparent;
  margin: 5px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button_number.active {
  background: linear-gradient(310deg, rgb(0, 117, 255), rgb(33, 212, 253));
  color: #fff;
}

.button_number:hover {
  background: white;
  color: black;
}

.button_arrow:hover {
  background: white;
  color: black;
}

.button_arrow:disabled,
.button_arrow[disabled]{
  display: none;
}