.questions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 0rem 6rem; 
}

@media screen and (max-width: 800px) {
  .questions {
    margin: 0 1rem;
  }
}