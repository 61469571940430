.carousel-container {
    overflow: hidden;
    position: relative;
    height: 200px;
    width: 100%;
    margin-top: 2rem;
}

.carousel-title {
    font-size: 20px;
    font-weight: 600;
    color: whitesmoke;
    text-align: center;
    margin: 10px;
}

@media (min-width: 801px) {
    .carousel-title {
        font-size: 24px;
    }
}

.carousel-overlay {
    position: absolute;
    z-index: 10;
    backdrop-filter: blur(10px);
    width: 130px;
    height: 200px;
    background: rgba(0, 0, 0, 0.1);
}

.left-overlay {
    left: 0;
}

.right-overlay {
    right: 0;
}

.carousel-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% / 5);
    /* margin: 10px; */
    &:hover {
        transform: scale(0.9);
    }
}

.carousel-card img {
    width: 40%;
    filter: grayscale(100%) brightness(3.5);
}

@media (max-width: 800px) {
    .carousel-card {
        width: 100%;
        margin: 0px;
    }
}

.carousel-track {
    display: flex;
    position: absolute;
    left: 0;
    justify-content: center;
    align-items: center;
    /* height: 80px; */
    /* gap: 16px; */
    width: 200%;
    height: 100%;
    animation: slide 40s linear infinite;
    &:hover {
        animation-play-state: paused;
    }
}

@keyframes slide {
    0% {
        left: 0;
    }
    100% {
        left: -100%;
    }
}