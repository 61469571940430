/* .card{
  transition: transform .2s;
}

.card:hover {
  transform: scale(1.05);
} */

.useBlocks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 25px;
  margin: 25px;
  width: 550px;
  height: fit-content;
}

.useBlocks:hover {
  /* background: linear-gradient(180deg, rgba(10,14,35,1) 29%, rgba(41,104,165,1) 130%, rgba(45,113,180,1) 100%) */
  background-image: linear-gradient(180deg,
      hsl(230deg 56% 9%) 0%,
      hsl(222deg 57% 21%) 55%,
      hsl(214deg 59% 33%) 75%,
      hsl(210deg 60% 40%) 85%,
      hsl(210deg 60% 42%) 93%,
      hsl(210deg 60% 44%) 100%);
}