.home {
  /* margin-top: 30px; */
  /* background-image: url("../public/images/wp9649930\ 1.jpg");
  background-size: cover; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  /* margin: 2rem 0rem; */
}
.background-video {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* margin-top: -35px; */
}

.background-video > .background-inner-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
  z-index: -10;
}

.home_headline {
  color: whitesmoke;
  padding: 30px;
  margin: 10px;
  min-width: 300px;
}

.home_firstSection {
  /* position: relative; */
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%);
  color: white;
  /* z-index: -1; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* margin-top: -15%; */
}

.home_secondSection {
  color: whitesmoke;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding-top: 5%;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 25px;
  margin: 5px;
  width: 350px;
  height: 200px;
}

.card:hover {
  /* background: linear-gradient(180deg, rgba(10,14,35,1) 29%, rgba(41,104,165,1) 130%, rgba(45,113,180,1) 100%) */
  background-image: linear-gradient(
    180deg,
    hsl(230deg 56% 9%) 0%,
    hsl(222deg 57% 21%) 55%,
    hsl(214deg 59% 33%) 75%,
    hsl(210deg 60% 40%) 85%,
    hsl(210deg 60% 42%) 93%,
    hsl(210deg 60% 44%) 100%
  );
}

/* .videoComponent{
  border-radius: '15px';
  margin-top: '25px';
  display: 'flex';
  align-items: 'center';
  opacity: 1;
  width: '60vw';
  box-shadow: '0 26px 58px 0 rgba(10, 0, 0, .82), 0 5px 14px 0 rgba(10, 0, 0, .18)';
} */

@media screen and (max-width: 800px) {
  .home_headline {
    margin: 5px 0px;
    margin-top: -30px;
  }

  .home_firstSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 5%; */
  }

  .home_secondSection {
    margin-top: 0px;
  }

  .card {
    width: 250px;
    height: 150px;
  }

  .background-video .background-inner-video {
    height: 60vh;
  }
}
.button2 {
  display: inline-block;
  text-transform: uppercase;
  transition: all 0.2s ease-in;
  position: relative;
  overflow: hidden;
  z-index: 1;
  color: #090909;
  padding: 0.8em 1.3em;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  border-radius: 20px;
  display: flex;
  font-family: "Plus Jakarta Display", Helvetica, Arial, sans-serif;
  align-items: center;
  /* background: #e8e8e8; */
  border: none;
  /* box-shadow: 0px 0px #c5c5c5, 0px 0px 10px lightgray; */
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

/* .button2:active {
  color: #666;
  box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
} */

.button2:before {
  content: "";
  position: absolute;
  left: 50%;
  /* transform: translateX(-50%) scaleY(1) scaleX(1.25); */
  top: 100%;
  width: 140%;
  height: 180%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.button2:after {
  content: "";
  position: absolute;
  left: 55%;
  transform: translateX(-50%) scaleY(1) scaleX(1.45);
  top: 180%;
  width: 160%;
  height: 190%;
  background-color: #090909;
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.button2:hover {
  color: #ffffff;
  border: 1px solid #090909;
  box-shadow: 0px 0px #c5c5c5, 0px 0px 50px lightgray;
}

.button2:hover:before {
  top: -35%;
  background-color: #090909;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.button2:hover:after {
  top: -45%;
  background-color: #090909;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}
