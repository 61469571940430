.blogDetails {
  flex: 0.8;
}

.blogPost, .popularBlog, .recentBlog {
  transition: transform .2s;
}

.blogPost:hover, .popularBlog:hover, .recentBlog:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 1000px) {
  .blogDetails {
    flex: 1
  }
}