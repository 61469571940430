.box-usecase {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4rem 6rem;
  color: whitesmoke;
}

/* .conversational_banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  margin-left: 40px;
  margin-right: 30px;
} */

.box-usecase-first {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 50px;
}

.box-usecase-second {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 50px;
}

@media screen and (max-width: 800px) {

  .box-usecase{
    padding: 0rem 2rem;
    align-items: center;
  }

  /* .conversational_banner{
    flex-direction: column;
    margin-top: 0px;
  } */

  .box-usecase-first{
    flex-direction:column;
    margin-top: 50px;
  }

  .box-usecase-second{
    flex-direction: column;
    margin-top: 50px;
  }
}