.chat-button-container {
  position: sticky;
  bottom: 50px;
  text-align: end;
  margin-right: 40px;
}

.chat-button {
  width: 200px; 
  height: 200px;
}

.chat-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

.chatwindow_footer {
  display: 'flex';
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  width: 300;
}

.video-container {
  position: relative;
  width: 200px;
  height: 200px;
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
  box-shadow: whitesmoke 0px 0px 10px 3px;
  margin-top: 10px;
  background: #fff;
  transition: opacity 0.5s ease-in-out 0s;
}

.video-container.loaded video {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .layout {
    padding: 0;
  } 

  .video-container {
    width: 150px;
    height: 150px;
  }
}