.policies-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4rem 14rem;
    color: whitesmoke;
}

ul li {
    margin-left: 15px;
}

ol li {
    margin-left: 20px;
}

.ul1 li {
    margin-left: 15px;
}

a {
    color: whitesmoke;
}

@media (max-width:800px) {
    .policies-container {
        padding: 4rem 4rem;
    }
}