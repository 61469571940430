@import url(https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/plus-jakarta-display.min.css);

/* Full Calendar */

.fc-toolbar-title {
  color: white !important;
}
a.fc-daygrid-day-number {
  width: 100%;
  text-align: center;
}
a.fc-daygrid-day-number {
  width: 100%;
  text-align: center;
}
a.fc-daygrid-day-number {
  width: 100%;
  text-align: center;
}
div.fc-daygrid-event-harness {
  top: unset;
}
.fc-daygrid-event-harness .fc-daygrid-event-harness-abs {
  top: 0px;
  bottom: unset;
}
.fc .fc-scrollgrid-section-header > *,
.fc .fc-scrollgrid-section-footer > * {
  border-right: 0px !important;
}
.fc-theme-standard .fc-scrollgrid {
  border: 0px solid transparent !important;
}

.fc-col-header-cell.fc-day.fc-day-mon {
  color: white;
  border-right: 0px;
  border-left: 0px;
}
.fc-col-header-cell.fc-day.fc-day-tue {
  color: white;
  border-left: 0px;
  border-right: 0px;
}
.fc-col-header-cell.fc-day.fc-day-wed {
  color: white;
  border-right: 0px;
  border-left: 0px;
}
.fc-col-header-cell.fc-day.fc-day-thu {
  color: white;
  border-left: 0px;
  border-right: 0px;
}
.fc-col-header-cell.fc-day.fc-day-fri {
  color: white;
  border-right: 0px;
  border-left: 0px;
}
.fc-col-header-cell.fc-day.fc-day-sat {
  color: white;
  border-left: 0px;
  border-right: 0px;
}
.fc-col-header-cell.fc-day.fc-day-sun {
  color: white;
  border-left: 0px;
  border-right: 0px;
}

.fc-daygrid-event {
  border-radius: 10px !important;
  text-align: center !important;
  font-family: "Plus Jakarta Display" !important;
}
.fc-day {
  color: white;
}
.fc-day.fc-day-mon {
  border: 1px solid !important;
  border-color: #292e5d !important;
}
.fc-day.fc-day-tue {
  border: 1px solid !important;
  border-color: #292e5d !important;
}
.fc-day.fc-day-wed {
  border: 1px solid !important;
  border-color: #292e5d !important;
}
.fc-day.fc-day-thu {
  border: 1px solid !important;
  border-color: #292e5d !important;
}
.fc-day.fc-day-fri {
  border: 1px solid !important;
  border-color: #292e5d !important;
}
.fc-day.fc-day-sat {
  border: 1px solid !important;
  border-color: #292e5d !important;
}
.fc-day.fc-day-sun {
  border: 1px solid !important;
  border-color: #292e5d !important;
}

.fc-scrollgrid.fc-scrollgrid-liquid > td {
  border: 0px;
}

.fc .fc-scrollgrid-section > td {
  border: 0;
}

/* React Kanban */
body .react-kanban-column {
  background: linear-gradient(
    127.09deg,
    rgba(6, 11, 40, 0.94) 19.41%,
    rgba(10, 14, 35, 0.49) 76.65%
  );
  background-color: unset !important;
  border-radius: 15px;
}
.react-kanban-column {
  width: 500px;
}
.react-kanban-card-adder-form {
  width: 100%;
  background-color: red;
  border-radius: 15px;
}
.react-kanban-card-adder-button {
  display: none;
  background-color: red !important;
  border-radius: 15px;
}

/* Text Editor */

body .ql-toolbar.ql-snow {
  background-color: #0f1535 !important;
  border: 0.5px solid #e2e8f04d;
}

body .ql-container.ql-snow {
  background-color: #0f1535 !important;
  border: 0.5px solid #e2e8f04d;
}

body .ql-snow .ql-picker-label {
  color: rgba(255, 255, 255, 0.92) !important;
}

body .ql-snow .ql-stroke {
  stroke: rgba(255, 255, 255, 0.92) !important;
}

body .ql-snow .ql-fill {
  fill: rgba(255, 255, 255, 0.92) !important;
}

/* .ql-snow .ql-picker-label:hover {
    color: rgba(255, 255, 255, 0.92) !important;
  }
  
  .ql-snow .ql-stroke:hover {
    stroke: rgba(255, 255, 255, 0.92) !important;
  }
  
  .ql-snow .ql-fill:hover {
    fill: rgba(255, 255, 255, 0.92) !important;
  } */

.ql-picker-options {
  background: #0f1535 !important;
  border: 0.5px solid #e2e8f04d;
}

.ql-container.ql-snow {
  color: rgb(160, 174, 192);
  min-height: 88px !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 2rem 4rem;
  background: linear-gradient(180deg, rgba(12,8,24,1) 1%, rgba(21,20,52,1) 90%);
}


/* .menuIcon {
  display: none;
} */

.navbar_items {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 700px;
    color: whitesmoke;
    cursor: pointer;
}

@media screen and (max-width: 1200px) {
    .navbar {
        padding: 20px;
        margin: 1rem;
    }
}

@media screen and (max-width: 1000px) {
    .navbar {
        padding: 20px;
        margin: 0px 2px;
    }
    .navbar_items {
        display: none;
    }
    .navbar_items.show {
        display: flex;
        flex-direction: column;
        background: black;
        position: absolute;
        text-align: center;
        top: 2%;
        left: 0%;
        width: 100%;
        padding: 20px;
        z-index: 99;
        align-items: center;
        height: 320px;
    }
    .navbar_items.show a {
        margin-bottom: 10px;
        /* Add some spacing between menu items */
    }
}

.button {
    line-height: 1;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    grid-gap: .75rem;
    gap: .75rem;
    background-color: var(--clr);
    color: #0e0e0e;
    border-radius: 10rem;
    font-weight: 500;
    padding: .55rem 1.3rem;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: background-color .3s;
    font-size: 14px;
}

.button__icon-wrapper {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    position: relative;
    color: var(--clr);
    background-color: #000;
    border-radius: 50%;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.button:hover {
    background-color: #000;
    color: #fff;
    /* border: 1px solid white; */
}

.button:hover .button__icon-wrapper {
    color: #fff;
}

.button__icon-svg--copy {
    position: absolute;
    -webkit-transform: translate(-150%, 150%);
            transform: translate(-150%, 150%);
}

.button:hover .button__icon-svg:first-child {
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    -webkit-transform: translate(150%, -150%);
            transform: translate(150%, -150%);
}

.button:hover .button__icon-svg--copy {
    transition: -webkit-transform .3s ease-in-out .1s;
    transition: transform .3s ease-in-out .1s;
    transition: transform .3s ease-in-out .1s, -webkit-transform .3s ease-in-out .1s;
    -webkit-transform: translate(0);
            transform: translate(0);
}
.home {
  /* margin-top: 30px; */
  /* background-image: url("../public/images/wp9649930\ 1.jpg");
  background-size: cover; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  /* margin: 2rem 0rem; */
}
.background-video {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* margin-top: -35px; */
}

.background-video > .background-inner-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
  z-index: -10;
}

.home_headline {
  color: whitesmoke;
  padding: 30px;
  margin: 10px;
  min-width: 300px;
}

.home_firstSection {
  /* position: relative; */
  position: absolute;
  top: 5%;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  color: white;
  /* z-index: -1; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* margin-top: -15%; */
}

.home_secondSection {
  color: whitesmoke;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding-top: 5%;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 25px;
  margin: 5px;
  width: 350px;
  height: 200px;
}

.card:hover {
  /* background: linear-gradient(180deg, rgba(10,14,35,1) 29%, rgba(41,104,165,1) 130%, rgba(45,113,180,1) 100%) */
  background-image: linear-gradient(
    180deg,
    hsl(230deg 56% 9%) 0%,
    hsl(222deg 57% 21%) 55%,
    hsl(214deg 59% 33%) 75%,
    hsl(210deg 60% 40%) 85%,
    hsl(210deg 60% 42%) 93%,
    hsl(210deg 60% 44%) 100%
  );
}

/* .videoComponent{
  border-radius: '15px';
  margin-top: '25px';
  display: 'flex';
  align-items: 'center';
  opacity: 1;
  width: '60vw';
  box-shadow: '0 26px 58px 0 rgba(10, 0, 0, .82), 0 5px 14px 0 rgba(10, 0, 0, .18)';
} */

@media screen and (max-width: 800px) {
  .home_headline {
    margin: 5px 0px;
    margin-top: -30px;
  }

  .home_firstSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 5%; */
  }

  .home_secondSection {
    margin-top: 0px;
  }

  .card {
    width: 250px;
    height: 150px;
  }

  .background-video .background-inner-video {
    height: 60vh;
  }
}
.button2 {
  display: inline-block;
  text-transform: uppercase;
  transition: all 0.2s ease-in;
  position: relative;
  overflow: hidden;
  z-index: 1;
  color: #090909;
  padding: 0.8em 1.3em;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  border-radius: 20px;
  display: flex;
  font-family: "Plus Jakarta Display", Helvetica, Arial, sans-serif;
  align-items: center;
  /* background: #e8e8e8; */
  border: none;
  /* box-shadow: 0px 0px #c5c5c5, 0px 0px 10px lightgray; */
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

/* .button2:active {
  color: #666;
  box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
} */

.button2:before {
  content: "";
  position: absolute;
  left: 50%;
  /* transform: translateX(-50%) scaleY(1) scaleX(1.25); */
  top: 100%;
  width: 140%;
  height: 180%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.button2:after {
  content: "";
  position: absolute;
  left: 55%;
  -webkit-transform: translateX(-50%) scaleY(1) scaleX(1.45);
          transform: translateX(-50%) scaleY(1) scaleX(1.45);
  top: 180%;
  width: 160%;
  height: 190%;
  background-color: #090909;
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.button2:hover {
  color: #ffffff;
  border: 1px solid #090909;
  box-shadow: 0px 0px #c5c5c5, 0px 0px 50px lightgray;
}

.button2:hover:before {
  top: -35%;
  background-color: #090909;
  -webkit-transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
          transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.button2:hover:after {
  top: -45%;
  background-color: #090909;
  -webkit-transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
          transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.information {
  color: whitesmoke;
  margin-top: 100px;
  margin: 4rem 6rem;
  font-family: "Plus Jakarta Display", Helvetica, Arial, sans-serif !important;
}

.information_heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 50px;
}

.horizontal-line {
  position: relative;
  width: 350px;
  height: 0;
  border-top: 2px solid #22AADD;
  margin: 30px 0;
  display: flex;
}

.horizontal-line::before,
.horizontal-line::after {
  content: '';
  position: absolute;
  top: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-radius: 100%;
}

.horizontal-line::before {
  left: -8px;
  border-width: 8px;
  border-color: #22AADD;
}

.horizontal-line::after {
  right: -8px;
  border-width: 8px;
  border-color:  #22AADD;
}

.first_hover:hover {
  
}
.second_hover:hover {

}
.third_hover:hover {

}

.custom-btn {
  width: 150px;
  height: 50px;
  color: #fff;
  border-radius: 10px;
  padding: 10px 25px;
  /* font-family: 'Lato', sans-serif; */
  font-weight: 400;
  /* background: transparent; */
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  /* box-shadow: 1px 1px 24px 1px rgba(5,5,5,0.74); */
  outline: none;
}

/* 11 */
.btn-11 {
  border: none;
  background: black;
  color: #fff;
  overflow: hidden;
  text-transform: uppercase;
}
.btn-11:hover {
    text-decoration: none;
    color: #fff;
}
.btn-11:before {
    position: absolute;
    content: '';
    display: inline-block;
    top: -180px;
    left: 0;
    width: 30px;
    height: 100%;
    background-color: #fff;
    -webkit-animation: shiny-btn1 3s ease-in-out infinite;
            animation: shiny-btn1 3s ease-in-out infinite;
}
.btn-11:hover{
  opacity: .7;
}
.btn-11:active{
  box-shadow:  4px 4px 6px 0 rgba(255,255,255,.3),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, .2);
}

@-webkit-keyframes shiny-btn1 {
    0% { -webkit-transform: scale(0) rotate(45deg); opacity: 0; }
    80% { -webkit-transform: scale(0) rotate(45deg); opacity: 0.5; }
    81% { -webkit-transform: scale(4) rotate(45deg); opacity: 1; }
    100% { -webkit-transform: scale(50) rotate(45deg); opacity: 0; }
}

@keyframes shiny-btn1 {
    0% { -webkit-transform: scale(0) rotate(45deg); opacity: 0; }
    80% { -webkit-transform: scale(0) rotate(45deg); opacity: 0.5; }
    81% { -webkit-transform: scale(4) rotate(45deg); opacity: 1; }
    100% { -webkit-transform: scale(50) rotate(45deg); opacity: 0; }
}

@media screen and (max-width: 800px) {
  .information {
    margin: 0;
  }

  .horizontal-line {
    width: 200px;
    margin: 30px auto;
  }
}
.questions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 0rem 6rem; 
}

@media screen and (max-width: 800px) {
  .questions {
    margin: 0 1rem;
  }
}
.footer {
  /* background-color: #000749; */
  color: white;
  height: 300px;
  display: flex;
  flex-direction: column;
  padding: 4rem 9rem;
  margin-top: 50px;
  margin-bottom: 30px;
}

@media screen and (max-width: 1000px) {
  .footer {
    padding: 3rem;
  }
}
.conversational {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4rem 6rem;
  color: whitesmoke;
}

.videoTutorial {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10rem;
  color: whitesmoke;
}

.videoTutorialContent {
  margin-top: 10%;
}

.videoTutorialBanner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 50px; */
  margin-left: 40px;
  margin-right: 30px;
}

.contentInnerTable {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.eachInnerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.eachInnerContent img {
  margin-top: 30px;
}

.conversational_banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  margin-left: 40px;
  margin-right: 30px;
}

.conversational_personalizedcustomer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 150px;
}

.conversational_personify {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 150px;
}

@media screen and (max-width: 800px) {

  .conversational {
    /* padding: 0rem 2rem; */
    align-items: center;
  }

  .conversational_banner {
    flex-direction: column;
    margin-top: 0px;
  }

  .conversational_personalizedcustomer {
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .conversational_personify {
    flex-direction: column;
    margin-top: 50px;
  }
}

.horizontal_line {
  position: relative;
  width: 350px;
  height: 0;
  border-top: 2px solid #22AADD;
  border-radius: 100%;
  margin: 100px auto 0 auto;
  display: flex;
}

.texttovideo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4rem 6rem;
  color: whitesmoke;
}

.texttovideo_banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  margin-left: 40px;
  margin-right: 30px;
}

@media screen and (max-width: 800px) {

  .horizontal_line {
    width: 300px;
    margin: 0;
  }

  .texttovideo {
    padding: 0rem 2rem;
    align-items: center;
  }

  .videoTutorial {
    padding: 2rem;
  }

  .contentInnerTable {
    flex-direction: column;
  }

  .texttovideo_banner {
    margin-top: 50px;
    flex-direction: column;
  }

  .texttovideo_personalized {
    margin-top: 0px;
  }
}

.texttovideo_personalized {
  display: flex;
  flex-direction: column;
  margin-top: 150px;
}

.personalization {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4rem 6rem;
  color: whitesmoke;
  background: #0C0818;
}

.personalization_banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  margin-left: 40px;
  margin-right: 30px;
}

@media screen and (max-width: 800px) {

  .personalization {
    /* padding: 0rem 2rem; */
    align-items: center;
  }

  .personalization_banner {
    flex-direction: column;
    margin-top: 0px;
  }
}

.useslides {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 4rem 6rem;
  color: whitesmoke;
  margin-top: 150px;
}

.useslides_firstcolumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.useslides_firstcolumn_row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 15px;
}

.useslides_firstcolumn_row p {
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  color: lightgray;
  cursor: pointer;
}

.useslides_firstcolumn_row p:active {
  color: whitesmoke;
}

.useslides_content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 50px;
}

.wrap {
  margin: 100px;
}

.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7%;
}

.carousel {
  position: relative;
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-bottom: 68px;
}

.carousel__item {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  padding: 0 10px;
  opacity: 0;
  -webkit-filter: drop-shadow(0 2px 2px #555);
          filter: drop-shadow(0 2px 2px #555);
  will-change: transform, opacity;
  -webkit-animation: carousel-animate-vertical 30s linear infinite;
  animation: carousel-animate-vertical 30s linear infinite;
}

.carousel__item:nth-child(1) {
  -webkit-animation-delay: calc(3s * -1);
  animation-delay: calc(3s * -1);
}

.carousel__item:nth-child(2) {
  -webkit-animation-delay: calc(3s * 0);
  animation-delay: calc(3s * 0);
}

.carousel__item:nth-child(3) {
  -webkit-animation-delay: calc(3s * 1);
  animation-delay: calc(3s * 1);
}

.carousel__item:nth-child(4) {
  -webkit-animation-delay: calc(3s * 2);
  animation-delay: calc(3s * 2);
}

.carousel__item:nth-child(5) {
  -webkit-animation-delay: calc(3s * 3);
  animation-delay: calc(3s * 3);
}

.carousel__item:nth-child(6) {
  -webkit-animation-delay: calc(3s * 4);
  animation-delay: calc(3s * 4);
}

.carousel__item:nth-child(7) {
  -webkit-animation-delay: calc(3s * 5);
  animation-delay: calc(3s * 5);
}

.carousel__item:nth-child(8) {
  -webkit-animation-delay: calc(3s * 6);
  animation-delay: calc(3s * 6);
}

.carousel__item:nth-child(9) {
  -webkit-animation-delay: calc(3s * 7);
  animation-delay: calc(3s * 7);
}

.carousel__item:last-child {
  -webkit-animation-delay: calc(-3s * 2);
  animation-delay: calc(-3s * 2);
}

@-webkit-keyframes carousel-animate-vertical {
  0% {
    -webkit-transform: translateY(100%) scale(0.5);
            transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }

  3%,
  11.1111111111% {
    -webkit-transform: translateY(100%) scale(0.7);
            transform: translateY(100%) scale(0.7);
    opacity: 0.2;
    visibility: visible;
  }

  14.1111111111%,
  22.2222222222% {
    -webkit-transform: translateY(0) scale(1);
            transform: translateY(0) scale(1);
    opacity: 1;
    visibility: visible;
  }

  25.2222222222%,
  33.3333333333% {
    -webkit-transform: translateY(-100%) scale(0.7);
            transform: translateY(-100%) scale(0.7);
    opacity: 0.2;
    visibility: visible;
  }

  36.3333333333% {
    -webkit-transform: translateY(-100%) scale(0.5);
            transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: visible;
  }

  100% {
    -webkit-transform: translateY(-100%) scale(0.5);
            transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes carousel-animate-vertical {
  0% {
    -webkit-transform: translateY(100%) scale(0.5);
            transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }

  3%,
  11.1111111111% {
    -webkit-transform: translateY(100%) translateX(-15%) scale(0.7);
            transform: translateY(100%) translateX(-15%) scale(0.7);
    opacity: 0.2;
    visibility: visible;
  }

  14.1111111111%,
  22.2222222222% {
    -webkit-transform: translateY(0) scale(1);
            transform: translateY(0) scale(1);
    opacity: 1;
    visibility: visible;
  }

  25.2222222222%,
  33.3333333333% {
    -webkit-transform: translateY(-100%) translateX(-15%) scale(0.7);
            transform: translateY(-100%) translateX(-15%) scale(0.7);
    opacity: 0.2;
    visibility: visible;
  }

  36.3333333333% {
    -webkit-transform: translateY(-100%) scale(0.5);
            transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: visible;
  }

  100% {
    -webkit-transform: translateY(-100%) scale(0.5);
            transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
}
.chat-button-container {
  position: -webkit-sticky;
  position: sticky;
  bottom: 50px;
  text-align: end;
  margin-right: 40px;
}

.chat-button {
  width: 200px; 
  height: 200px;
}

.chat-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

.chatwindow_footer {
  display: 'flex';
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  width: 300;
}

.video-container {
  position: relative;
  width: 200px;
  height: 200px;
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
  box-shadow: whitesmoke 0px 0px 10px 3px;
  margin-top: 10px;
  background: #fff;
  transition: opacity 0.5s ease-in-out 0s;
}

.video-container.loaded video {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .layout {
    padding: 0;
  } 

  .video-container {
    width: 150px;
    height: 150px;
  }
}
/* .card{
  transition: transform .2s;
}

.card:hover {
  transform: scale(1.05);
} */

.useBlocks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 25px;
  margin: 25px;
  width: 550px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.useBlocks:hover {
  /* background: linear-gradient(180deg, rgba(10,14,35,1) 29%, rgba(41,104,165,1) 130%, rgba(45,113,180,1) 100%) */
  background-image: linear-gradient(180deg,
      hsl(230deg 56% 9%) 0%,
      hsl(222deg 57% 21%) 55%,
      hsl(214deg 59% 33%) 75%,
      hsl(210deg 60% 40%) 85%,
      hsl(210deg 60% 42%) 93%,
      hsl(210deg 60% 44%) 100%);
}
.blogDetails {
  flex: 0.8 1;
}

.blogPost, .popularBlog, .recentBlog {
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.blogPost:hover, .popularBlog:hover, .recentBlog:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

@media screen and (max-width: 1000px) {
  .blogDetails {
    flex: 1 1
  }
}
.box-usecase {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4rem 6rem;
  color: whitesmoke;
}

/* .conversational_banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  margin-left: 40px;
  margin-right: 30px;
} */

.box-usecase-first {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 50px;
}

.box-usecase-second {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 50px;
}

@media screen and (max-width: 800px) {

  .box-usecase{
    padding: 0rem 2rem;
    align-items: center;
  }

  /* .conversational_banner{
    flex-direction: column;
    margin-top: 0px;
  } */

  .box-usecase-first{
    flex-direction:column;
    margin-top: 50px;
  }

  .box-usecase-second{
    flex-direction: column;
    margin-top: 50px;
  }
}
.rotate {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.pagination {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  justify-content: flex-end;
}

.button_arrow {
  border-radius: 100%;
  padding: 8px 10px;
  border: 1px solid gray;
  color: gray;
  background: transparent;
  margin: 5px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button_number {
  border-radius: 100%;
  padding: 8px 13px;
  border: 1px solid gray;
  color: gray;
  background: transparent;
  margin: 5px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button_number.active {
  background: linear-gradient(310deg, rgb(0, 117, 255), rgb(33, 212, 253));
  color: #fff;
}

.button_number:hover {
  background: white;
  color: black;
}

.button_arrow:hover {
  background: white;
  color: black;
}

.button_arrow:disabled,
.button_arrow[disabled]{
  display: none;
}
.carousel-container {
    overflow: hidden;
    position: relative;
    height: 200px;
    width: 100%;
    margin-top: 2rem;
}

.carousel-title {
    font-size: 20px;
    font-weight: 600;
    color: whitesmoke;
    text-align: center;
    margin: 10px;
}

@media (min-width: 801px) {
    .carousel-title {
        font-size: 24px;
    }
}

.carousel-overlay {
    position: absolute;
    z-index: 10;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    width: 130px;
    height: 200px;
    background: rgba(0, 0, 0, 0.1);
}

.left-overlay {
    left: 0;
}

.right-overlay {
    right: 0;
}

.carousel-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% / 5);
    /* margin: 10px; */
    &:hover {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
}

.carousel-card img {
    width: 40%;
    -webkit-filter: grayscale(100%) brightness(3.5);
            filter: grayscale(100%) brightness(3.5);
}

@media (max-width: 800px) {
    .carousel-card {
        width: 100%;
        margin: 0px;
    }
}

.carousel-track {
    display: flex;
    position: absolute;
    left: 0;
    justify-content: center;
    align-items: center;
    /* height: 80px; */
    /* gap: 16px; */
    width: 200%;
    height: 100%;
    -webkit-animation: slide 40s linear infinite;
            animation: slide 40s linear infinite;
    &:hover {
        -webkit-animation-play-state: paused;
                animation-play-state: paused;
    }
}

@-webkit-keyframes slide {
    0% {
        left: 0;
    }
    100% {
        left: -100%;
    }
}

@keyframes slide {
    0% {
        left: 0;
    }
    100% {
        left: -100%;
    }
}
.policies-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4rem 14rem;
    color: whitesmoke;
}

ul li {
    margin-left: 15px;
}

ol li {
    margin-left: 20px;
}

.ul1 li {
    margin-left: 15px;
}

a {
    color: whitesmoke;
}

@media (max-width:800px) {
    .policies-container {
        padding: 4rem 4rem;
    }
}
