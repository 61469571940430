.footer {
  /* background-color: #000749; */
  color: white;
  height: 300px;
  display: flex;
  flex-direction: column;
  padding: 4rem 9rem;
  margin-top: 50px;
  margin-bottom: 30px;
}

@media screen and (max-width: 1000px) {
  .footer {
    padding: 3rem;
  }
}