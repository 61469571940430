.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 2rem 4rem;
  background: linear-gradient(180deg, rgba(12,8,24,1) 1%, rgba(21,20,52,1) 90%);
}


/* .menuIcon {
  display: none;
} */

.navbar_items {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 700px;
    color: whitesmoke;
    cursor: pointer;
}

@media screen and (max-width: 1200px) {
    .navbar {
        padding: 20px;
        margin: 1rem;
    }
}

@media screen and (max-width: 1000px) {
    .navbar {
        padding: 20px;
        margin: 0px 2px;
    }
    .navbar_items {
        display: none;
    }
    .navbar_items.show {
        display: flex;
        flex-direction: column;
        background: black;
        position: absolute;
        text-align: center;
        top: 2%;
        left: 0%;
        width: 100%;
        padding: 20px;
        z-index: 99;
        align-items: center;
        height: 320px;
    }
    .navbar_items.show a {
        margin-bottom: 10px;
        /* Add some spacing between menu items */
    }
}

.button {
    line-height: 1;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    gap: .75rem;
    background-color: var(--clr);
    color: #0e0e0e;
    border-radius: 10rem;
    font-weight: 500;
    padding: .55rem 1.3rem;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: background-color .3s;
    font-size: 14px;
}

.button__icon-wrapper {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    position: relative;
    color: var(--clr);
    background-color: #000;
    border-radius: 50%;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.button:hover {
    background-color: #000;
    color: #fff;
    /* border: 1px solid white; */
}

.button:hover .button__icon-wrapper {
    color: #fff;
}

.button__icon-svg--copy {
    position: absolute;
    transform: translate(-150%, 150%);
}

.button:hover .button__icon-svg:first-child {
    transition: transform .3s ease-in-out;
    transform: translate(150%, -150%);
}

.button:hover .button__icon-svg--copy {
    transition: transform .3s ease-in-out .1s;
    transform: translate(0);
}